

import { Vue, Component } from 'vue-property-decorator'
import { Machine } from '@/types/material.d'

@Component
export default class extends Vue {
  formData = {
    machineryName: '', // 机械名称
    specificationModel: '', // 规格/型号
    supplierId: '', // 所属供应商
    projectName: '', // 所属项目
    pricingMethod: '', // 计价方式
    unitPrice: '', // 含税单价
    whetherOil: '2', // 是否含油
    driverName: '', // 驾驶员姓名
    identityCard: '', // 驾驶证号
    mobileNumber: '', // 手机号
    engineNumber: '', // 发动机编号
    remarks: '' // 备注
  }

  get machineryId () {
    return this.$route.query.machineryId as string
  }

  created () {
    this.loadData()
  }

  loadData () {
    this.$axios.get<Machine>(this.$apis.material.selectYhMachineryByMachineryId, {
      machineryId: this.machineryId
    }).then(res => {
      this.formData = res
    })
  }
}
